import Util from './util.js';
import SymfonyCollection from './symfonyCollection.js';

class Admin {
    constructor(){
        Util.init();
        new SymfonyCollection(Util.init, true);
        window.SymfonyCollection = SymfonyCollection;

        $(document)
            .on('click', '.crud-tabs', e => {
                // Set window url with new tab selected
                e.preventDefault();
                window.history.pushState('', '', `${window.location.pathname}?tab=${e.target.getAttribute('data-name')}`);
            });
        ;
    }
}

$(document).ready(() => new Admin());
