import Autocomplete from "./autocomplete";

export default class Util {

    static initDatePicker(parent) {
        parent = parent || document;

        $(parent).find('.datepicker:not(.datetimepicker)').datetimepicker({
            locale:   'fr',
            format:   'DD/MM/YYYY',
            showClear: true
        });

        $(parent).find('.datetimepicker').datetimepicker({
            locale:   'fr',
            showClear: true,
            stepping: 30
        });

        // without year
        $(parent).find('.daymonthpicker').datetimepicker({
            locale:   'fr',
            format:   'DD/MM',
            showClear: true,
            dayViewHeaderFormat: 'MMMM'
        });
    }

    static initSelect2(parent){
        parent = parent || document;

        $(parent).find(".select2:not(.disabled-search)").select2({
            minimumResultsForSearch: 10,
            tags: true,
            language: {
                noResults: () => 'Aucun resultat',
            }
        });

        $(parent).find(".select2.disabled-search").select2({
            minimumResultsForSearch: -1,
            tags: true,
            language: {
                noResults: () => 'Aucun resultat',
            }
        });
    }

    static initNumberTouchSpin(parent){
        parent = parent || document;
        let touchSpin = $(parent).find(".touchspin");
        $(touchSpin).each(function() {
            const spin = $(this);
            const step = spin.attr('step') || 1;
            const decimal = (step.indexOf('.') !== -1) ? 2 : 0;

            spin.TouchSpin({
                min: spin.attr('min') || 0,
                max: parseInt(spin.attr('max')) || Infinity,
                step: step,
                decimals: decimal
            });
        });
    }

    static initTimePicker(parent) {
        parent = parent || document;

        $(parent).find('.timepicker').datetimepicker({
            format: 'LT',
            locale: 'fr',
            stepping: 30
        });
    }

    static initAutocomplete(parent) {
        parent = parent || document;

        new Autocomplete(parent);
    }

    static init(parent){
        Util.initTimePicker(parent);
        Util.initDatePicker(parent);
        Util.initSelect2(parent);
        Util.initNumberTouchSpin(parent);
        Util.initAutocomplete(parent);
    }
}
