/**
 * Autocomplete jQuery
 */
export default class Autocomplete {
    constructor(parent){
        parent = parent || document;
        Autocomplete.init(parent, '.autocomplete', '/admin/api/autocomplete');
    }

    static init(parent, selector, url) {
        $(parent).find(selector).each(function() {
            const input         = $(this);
            const classEntity   = input.attr('data-entity');
            const classProperty = input.attr('data-property');
            const allowClear    = input.attr('data-allow-clear');
            let extraProperty   = input.attr('data-extra-property');

            if (undefined === extraProperty) {
                extraProperty = null;
            }

            if (classEntity && classProperty) {
                input.select2({
                    placeholder: 'Aucune sélection',
                    allowClear: allowClear,
                    minimumResultsForSearch: 0,
                    minimumInputLength: 1,
                    language: {
                        searching: () => 'Chargement...',
                        inputTooShort: () => 'Veuillez saisir des caractères',
                        noResults: () => 'Aucun resultat',
                    },
                    ajax: {
                        delay: 250,
                        url : url,
                        data: function(params){
                            params.class    = classEntity;
                            params.property = classProperty;
                            params.extra    = extraProperty;

                            return {
                                search: params,
                                type: 'public'
                            }
                        },
                        processResults: data => ({ results: data.items })
                    },
                });

                input.on('change', () => {
                    let value = input.select2('val');

                    if (Array.isArray(value)) {
                        value = value.join(',');
                    }

                    $('#' + input.data('in')).val(value);
                });
            }
        });
    }
}
