/**
 * @class SymfonyCollection
 *
 * Add a widget to a collection
 */
export default class SymfonyCollection {
  /**
   *  constructor
   *  @param callback function
   */
  constructor(callback) {
      SymfonyCollection.initClickEvent(document, callback);
      SymfonyCollection.initDeleteClick(document);
  }

  /**
   * Active the click
   *
   * @param parent
   * @param callback
   */
  static initClickEvent(parent, callback){
      parent = parent || document;
      const addItemAction = parent.querySelectorAll('.add-item');

      Object.keys(addItemAction).forEach(key => {
          const collection = SymfonyCollection.getCollection(addItemAction[key]);
          addItemAction[key].addEventListener('click', () => {
              SymfonyCollection.insertNewWidget(collection, callback);
          });
      });
  }

  /**
   * insertNewWidget
   * Add the widget as define inside the prototype
   * @param collection    selector
   * @param callback        Function to execute
   */
  static insertNewWidget(collection, callback) {
    const counter = collection.childElementCount;
    let prototype = collection.getAttribute('data-prototype');

    // Retrieve and add the number of entity for this collection
    prototype = prototype.replace(/__name__/g, counter);

    if (collection.children.length === 0) {
        const collectionId = collection.getAttribute('id');
        SymfonyCollection.displayNoResultMsg(collectionId, false);
    }

    collection.insertAdjacentHTML('beforeend', prototype);
    let lastChild = collection.children[(collection.children.length - 1)];

    let btnAdd = lastChild.querySelector('.add-item');
    let btnRemove = lastChild.querySelector('.collection-delete');

    if (btnAdd !== null) {
       SymfonyCollection.initClickEvent(lastChild);

    }

    if (null !== btnRemove) {
        SymfonyCollection.initDeleteClick(lastChild);
    }

    if (typeof callback === 'function') {
      callback(lastChild);
    }
  }

  static initDeleteClick(parent){
      const removeItemAction = parent.querySelectorAll('.collection-delete');

      Object.keys(removeItemAction).forEach(key => {
          removeItemAction[key].addEventListener('click', () => {
              let parent = SymfonyCollection.getParent(removeItemAction[key], 'SECTION');
              let fieldSet = SymfonyCollection.getParent(parent.parentElement, 'SECTION');
              parent.remove();
              // Get the collection and if doesn't have child then show no element.
              let collection = fieldSet.querySelector('.table-collection');
              if (collection.children.length === 0) {
                SymfonyCollection.displayNoResultMsg(collection.getAttribute('id'));
              }
          });
      });
  }

  /**
   * Remove the closest parent with TR tag
   * @param widget
   * @param tagName
   */
  static removeWidget(widget, tagName){
      tagName = tagName || 'TR';

      widget = SymfonyCollection.getParent(widget, tagName);

      if(widget !== undefined && widget !== null) {
        const tbody = widget.parentElement;
        if(tbody && tbody.children.length === 1) {
          // last child will be removed, we display no result msg
          SymfonyCollection.displayNoResultMsg(tbody.getAttribute('id'));
        }

        widget.remove();
      }
  }

  /**
   * Get the collection
   *
   * @param widget
   * @returns {Element | any}
   */
  static getCollection(widget){
      widget = SymfonyCollection.getParent(widget, 'SECTION');

      return widget.querySelector('.table-collection');
  }

  static getParent(widget, tagName){
      if (!tagName || !widget){
          return null;
      }

      while (widget !== undefined && widget !== null && widget.tagName !== tagName) {
          widget = widget.parentElement;
      }

      return widget
  }

  static displayNoResultMsg(collectionId, show = true) {
      const noResult = document.getElementById(`no-result-${collectionId}`);

      if (noResult) {
        show ? noResult.classList.remove('hidden') : noResult.classList.add('hidden');
      }
  }
}
